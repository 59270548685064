import React, { useState } from 'react'
import { CheckInProvider } from '../../Provider/CheckInProvider'
import CheckInDetail from '../../components/CheckIn/CheckInDetail'
import ModalCamera from '../../components/CheckIn/ModalCamera'
import TableCheckIn from '../../components/Tables/CheckIn/TableCheckIn'
import './style.scss'
const CheckIn = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }
  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <CheckInProvider>
      <div className="homeContainer">
        <div className="containerTitle">
          <h4>Check-in</h4>
          <p className="subtitle">Última identidad</p>
        </div>

        <CheckInDetail />
        <TableCheckIn handleOpenModal={handleOpenModal} />
        <ModalCamera
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onOpen={handleOpenModal}
        />
      </div>
    </CheckInProvider>
  )
}

export default CheckIn
