// src/services/routines.ts
import { Routines, RoutinesCreate, LocationsItems } from '../types/routines'
import { Pagination, PaginationQuery } from '../types/types'
import axiosInstance from './axiosInstance'

export const listRoutines = async ({
  id,
  params
}: {
  id: string
  params: PaginationQuery
}): Promise<Pagination<Routines>> => {
  try {
    const response = await axiosInstance.get(`/api/routines/byClient/${id}`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de Routiness:', error)
    throw error
  }
}

export const getLocations = async (id: string): Promise<LocationsItems[]> => {
  try {
    const response = await axiosInstance.get(`/api/routines/Locations/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de Routines:', error)
    throw error
  }
}

export const createRoutines = async (params: RoutinesCreate): Promise<Routines> => {
  try {
    const response = await axiosInstance.post('/api/routines', JSON.stringify(params), {})

    return response.data
  } catch (error) {
    console.error('Error en la creación de Routines:', error)
    throw error
  }
}

export const updateRoutines = async (
  id: string,
  params: RoutinesCreate
): Promise<Routines> => {
  try {
    const dataSend = JSON.stringify(params)

    const response = await axiosInstance.post(`/api/routines/${id}`, dataSend, {})

    return response.data
  } catch (error) {
    console.error('Error en la actualizacion de Routines:', error)
    throw error
  }
}

export const enableRoutines = async (
  id: string,
  params: {
    enable: boolean
    visitor: boolean
    guest: boolean
    worker: boolean
    delegate: boolean
  }
): Promise<Routines> => {
  try {
    const response = await axiosInstance.post(
      `/api/routines/enable/${id}`,
      JSON.stringify(params),
      {}
    )

    return response.data
  } catch (error) {
    console.error('Error en la Activación de Routines:', error)
    throw error
  }
}

export const NotificationRoutines = async (id: string, value: boolean): Promise<Routines> => {
  try {
    const response = await axiosInstance.post(`/api/routines/notification/${id}`, {
      enableNotification: value
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('error en actualizar notificaciones de rutines', error)
    throw error
  }
}

export const deleteRoutines = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.delete(`/api/routines/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Routines:', error)
    throw error
  }
}

export const CopyRoutines = async (id: string): Promise<void> => {
  try {
    const response = await axiosInstance.post(`/api/routines/copy/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Routines:', error)
    throw error
  }
}

export const regenerateCode = async (id: string): Promise<{ qr: string }> => {
  try {
    const response = await axiosInstance.post(`/api/routines/regenerate_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error al eliminar Routines:', error)
    throw error
  }
}

export const getRoutines = async (id: string): Promise<Routines> => {
  try {
    const response = await axiosInstance.get(`/api/routines/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la solicitud para obtener Routines:', error)
    throw error
  }
}

export const listByDestinationCode = async (params: {
  search: string
  clientId: string
}): Promise<Routines[]> => {
  try {
    const response = await axiosInstance.get(`/api/routines/search_code`, {
      params
    })

    return response.data
  } catch (error) {
    console.error('Error en la solicitud de la lista de Routiness:', error)
    throw error
  }
}

export const resetRoutinesQr = async (id: string): Promise<Routines> => {
  try {
    const response = await axiosInstance.post(`/api/routines/reset_qr/${id}`)

    return response.data
  } catch (error) {
    console.error('Error en la creación de Routines:', error)
    throw error
  }
}
