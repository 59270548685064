import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from '../components/LayoutComponents/Layout/Layout'
import PrivateRoute from '../components/PrivateRouter'
import Brands from './Brand'
import CheckIn from './Check_In'
import AccessConfig from './AccessConfig'
import CheckAcess from './CheckAcess'
import ClientDetailsPage from './ClientDetails'
import Clients from './Clients'
import DestinationPage from './Destination'
import Home from './Home'
import Login from './Login'
import ReceptionistPage from './Receptionist'
import ResidentPage from './Resident'
import ResidentDetailsPage from './ResidentDetails'
import SuperAdminPage from './SuperAdmin'
import Corps from './Corps'
import OriginsPage from './Origin'
import ReportsPage from './ReportsPage'
import StaffPage from './Staff'
import CheckPointsLocations from './CheckPoints/Locations'
import CheckPointsRoutines from './CheckPoints/Routines'
import CheckPointsDashboard from './CheckPoints/Dashboard'
import DashboardPage from './Dashboard'
import DashboardCorpPage from './DashboardCorp'
import Acreditamientos from './Acreditamientos'
import PaymentDatePage from './PaymentDate'
import PaymentconceptsPage from './PaymentconceptsPage'

import { roleEnum } from '../types/user'

const AppRouter: React.FC<{ isCheckIn: boolean }> = ({ isCheckIn }) => {
  if (isCheckIn) {
    return (
      <Layout>
        <CheckIn />
      </Layout>
    )
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <Layout>
            <PrivateRoute
              allowedRoles={[
                roleEnum.SUPER_ADMIN,
                roleEnum.ADMIN,
                roleEnum.RECEPTION,
                roleEnum.CORP
              ]}
            />
          </Layout>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />

        <Route element={<PrivateRoute allowedRoles={[roleEnum.RECEPTION]} />}>
          <Route path="/check_access" element={<CheckAcess />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[roleEnum.RECEPTION, roleEnum.ADMIN]} />}>
          <Route path="/check_in" element={<CheckIn />} />
          <Route path="/report" element={<ReportsPage />} />
        </Route>

        <Route
          element={<PrivateRoute allowedRoles={[roleEnum.SUPER_ADMIN, roleEnum.ADMIN]} />}
        >
        </Route>

        <Route element={<PrivateRoute allowedRoles={[roleEnum.SUPER_ADMIN]} />}>
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id" element={<ClientDetailsPage />} />
          <Route path="/super_admin" element={<SuperAdminPage />} />
          <Route path="/destination" element={<DestinationPage />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/origins" element={<OriginsPage />} />
          <Route path="/Corps" element={<Corps />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[roleEnum.CORP]} />}>
          <Route path="/dashboard_corp" element={<DashboardCorpPage />} />
          <Route path="/dashboard_corp/:id" element={<DashboardPage />} />
        </Route>

        <Route element={<PrivateRoute allowedRoles={[roleEnum.ADMIN]} />}>
          <Route path="/access" element={<AccessConfig />} />
          <Route path="/staff" element={<StaffPage />} />
          <Route path="/receptionist" element={<ReceptionistPage />} />
          <Route path="/resident" element={<ResidentPage />} />
          <Route path="/resident/:id" element={<ResidentDetailsPage />} />

          <Route path="/CheckPoints">
            <Route path="Locations" element={<CheckPointsLocations />} />
            <Route path="Routines" element={<CheckPointsRoutines />} />
            <Route path="Dashboard" element={<CheckPointsDashboard />} />
          </Route>

          <Route path="/accreditations">
            <Route path="concepts" element={<PaymentconceptsPage />} />
            <Route path="dates" element={<PaymentDatePage />} />
            <Route path="Dashboard" element={<Acreditamientos />} />
          </Route>

          <Route path="/dashboard" element={<DashboardPage />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRouter
