import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './style.scss'
import { useAuth } from '../../../Provider/AuthProvider'
import { DashCheckPoint } from '../../../types/dashboard'
import ReactECharts from 'echarts-for-react'
import Modal from '../../../components/GeneralComponents/Modal'
import ReportModal from '../../../components/modalDashboard/seeMoreCheckPoint'
import Button from '../../../components/Form/Button'

import { WorkHistory, ExpandLess, ExpandMore } from '@mui/icons-material/'
import { IconButton, Fade } from '@mui/material'
import { getGeneralCheckpointByClientId } from '../../../services/dashboard'

import { useLoading } from '../../../Provider/LoadingProvider'
import { LightTooltip } from '../../../components/tooltips/text'
import PhotoTooltip from '../../../components/tooltips/img'
import Icon from '@ant-design/icons'
import { ReactComponent as checkPoint1 } from '../../../assets/svg/checkPoint1.svg'
import { ReactComponent as checkPoint2 } from '../../../assets/svg/checkPoint2.svg'

import {
  faWrench,
  faCity,
  faHandSparkles,
  faCircle,
  faCircleNotch,
  faFlagCheckered,
  faUserSlash,
  faCircleArrowUp,
  faCircleArrowDown
} from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClientBanner from '../../../components/GeneralComponents/SliderBanner'

const Dashboard = () => {
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [DashCheckPoint, setDashCheckPoint] = useState<DashCheckPoint | null>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [idSearch, setIdSearch] = useState<string | undefined>(undefined)
  const [slider, setSlider] = useState(true)
  const [selected, setSelected] = useState<{ id: string; action: string } | undefined>(
    undefined
  )

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  useEffect(() => {

    if (idSearch) {
      getGeneralData(idSearch)

     
    }

  }, [idSearch])

  const getGeneralData = async (id: string) => {
    try {
      startLoading()
      const data = await getGeneralCheckpointByClientId(id)
      setDashCheckPoint(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  if (!DashCheckPoint)
    return (
      <div className="DashboardContainer">
        <div className="title_Dashboard">
          <h4>Dashboard</h4>
        </div>
      </div>
    )

  const barChartOptions = {
    title: {
      text: 'Limpieza / Mantenimiento',
      subtext: `Mes en curso ${months[new Date().getMonth()]}`,
      left: 'center',
      textStyle: {
        color: '#fff'
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },

    xAxis: {
      type: 'category',
      data: [
        'Total de acciones',
        'Acciones limpieza',
        'limpiezas completadas',
        'Acciones mantenimiento',
        'mantenimiento completados'
      ]
    },
    yAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#fff'
        }
      },
      splitLine: {
        lineStyle: {
          color: '#333' // Cambia el color de las líneas de división si lo necesitas
        }
      }
    },
    series: [
      {
        name: 'Acciones',
        type: 'bar',
        data: [
          { value: DashCheckPoint.T_actions, itemStyle: { color: '#3b82f6' } },
          {
            value: DashCheckPoint.T_action_Clear,
            itemStyle: { color: '#60a5fa' }
          },
          {
            value: DashCheckPoint.ok_action_Clear,
            itemStyle: { color: '#10b981' }
          },
          {
            value: DashCheckPoint.T_action_maintenance,
            itemStyle: { color: '#93c5fd' }
          },
          {
            value: DashCheckPoint.ok_action_maintenance,
            itemStyle: { color: '#34d399' }
          }
        ]
      }
    ]
  }

  const barHorizontal = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: DashCheckPoint.staffs.map(
        staff => `${staff.name} ${staff.Type ? staff.Type : ''}`
      ),
      type: 'scroll', // Permite la navegación si hay demasiados nombres
      textStyle: {
        fontSize: 14 // Ajusta el tamaño de la fuente para que quepan más nombres
      }
    },
    yAxis: {
      type: 'category',
      data: DashCheckPoint.staffs.map(
        staff => `${staff.name} \n ${staff.Type ? staff.Type : ''}`
      ),
      axisLabel: {
        interval: 0, // Muestra todas las etiquetas

        fontSize: 12 // Ajusta el tamaño de la fuente para que quepan mejor
      }
    },
    xAxis: {
      type: 'value',
      splitLine: {
        lineStyle: {
          color: '#fcfbff77' // Cambia el color de las líneas verticales aquí
        }
      }
    },
    grid: {
      left: '20%', // Deja espacio suficiente para las etiquetas
      right: '10%',
      bottom: '10%',
      top: '10%'
    },
    series: [
      {
        name: 'Staff S_TT',
        type: 'bar',
        data: DashCheckPoint.staffs.map(staff => staff.S_TR),
        label: {
          color: '#ffffff', // Cambiar color al hacer hover
          show: true,
          position: 'right' // Etiquetas al final de la barra
        },
        emphasis: {
          focus: 'series',
          itemStyle: {
            color: '#ff6347' // Cambiar color al hacer hover
          }
        }
      }
    ]
  }

  const handleView = (id: string, action: string) => {
    setSelected({ id, action })
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelected(undefined)
    if (idSearch) {
      getGeneralData(idSearch)
    }
  }

  const Actions: React.FC<{
    id: string
    state: string
  }> = ({ id, state }) => {
    const isDisabled = state === 'notTaken'
    const inProcess = state === 'inProgress'

    const disabledButtonStyles = {
      color: 'gray',
      pointerEvents: 'none'
    }

    const enabledButtonStyles = {
      color: 'white'
    }

    return (
      <>
        <LightTooltip title="Historial" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'record')}
              disabled={isDisabled}
              style={isDisabled ? disabledButtonStyles : enabledButtonStyles}
            >
              <WorkHistory />
            </IconButton>
          </div>
        </LightTooltip>

        <LightTooltip title="Desacreditar Trabajador" TransitionComponent={Fade}>
          <div>
            <IconButton
              onClick={() => handleView(id, 'discredit')}
              disabled={!inProcess}
              style={!inProcess ? disabledButtonStyles : enabledButtonStyles}
            >
              <FontAwesomeIcon icon={faUserSlash} />
            </IconButton>
          </div>
        </LightTooltip>
      </>
    )
  }

  const status: {
    [key: string]: string
  } = {
    finalized: 'Finalizado',
    inProgress: 'En Proceso',
    notTaken: 'No Tomado'
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    finalized: <FontAwesomeIcon icon={faFlagCheckered} className="dashBoardClose" />,
    inProgress: <FontAwesomeIcon icon={faCircleNotch} className="dashBoardOpen" />,
    notTaken: <FontAwesomeIcon icon={faCircle} className="dashBoardHold" />
  }
  const handleSendDiscredit = async () => {
    if (selected && idSearch) {
      closeModal()
    }
  }

  const Waring: React.FC = () => {
    return (
      <>
        <div className="WaringModal">
          <div className="Waring-header">
            <h2>ADVERTENCIA</h2>
          </div>
          <div className="Waring-text">
            Estas a punto de desincorporar al trabajador de este plan
            <br /> <br />
            <span>¿Estas seguro de hacer este cambio?</span>
          </div>

          <div className="containerButtons">
            <Button text="SI" styleType={`normal`} onClick={handleSendDiscredit} />

            <Button
              text="NO"
              styleType={`normal`}
              onClick={() => {
                setSelected(undefined)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="DashboardContainer">
      <div className="title_Dashboard">
        <h4>
          Dashboard CheckPoint {user?.role !== 'corporate' ? `` : DashCheckPoint.clientName}
        </h4>
      </div>
      {DashCheckPoint && (
        <div>
          {selected && (
            <>
              {selected.action === 'record' && (
                <Modal isOpen={isModalOpen} onClose={closeModal}>
                  <ReportModal
                    PreReport={
                      DashCheckPoint.dashCheckReport.find(report => report.id === selected.id)!
                    }
                    ClientName={DashCheckPoint.clientName!}
                  />
                </Modal>
              )}

              {selected.action === 'discredit' && (
                <Modal
                  isOpen={isModalOpen}
                  onClose={closeModal}
                  styleContainer={{ width: '480px', height: '270px' }}
                >
                  <Waring />
                </Modal>
              )}
            </>
          )}

          <div className="infoGeneralContainer">
            <div style={{ position: 'absolute', left: '-40px' }}>
              <IconButton onClick={() => setSlider(!slider)}>
                {slider ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>

            <LightTooltip title="Acciones totales" followCursor TransitionComponent={Fade}>
              <li className="generalInf tooltip">
                <FontAwesomeIcon icon={faCity} className="icon" />
                <span>{DashCheckPoint?.P_activity}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Acciones de limpieza  "
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faHandSparkles} className="icon" />
                <span>{DashCheckPoint.P_clear}%</span>
              </li>
            </LightTooltip>

            <LightTooltip
              title="Acciones en Mantenimiento"
              followCursor
              TransitionComponent={Fade}
            >
              <li className="generalInf">
                <FontAwesomeIcon icon={faWrench} className="icon" />
                <span>{DashCheckPoint.P_maintenance}%</span>
              </li>
            </LightTooltip>

            <li className="generalInf">
              <LightTooltip
                title="Cantidad tiempos fuera"
                followCursor
                TransitionComponent={Fade}
              >
                <li className="generalInf IconSvgCheck">
                  <Icon component={checkPoint2} className="icon" />
                  <span>{DashCheckPoint.P_TOut}</span>
                </li>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior"
                followCursor
                TransitionComponent={Fade}
              >
                <li className="generalInf">
                  <FontAwesomeIcon
                    icon={DashCheckPoint.P_lastTOut > 0 ? faCircleArrowUp : faCircleArrowDown}
                    className={`icon ${
                      DashCheckPoint.P_lastTOut > 0 ? 'icon-negative' : 'icon-positive'
                    }`}
                  />
                  <span>
                    {DashCheckPoint.P_lastTOut > 0
                      ? DashCheckPoint.P_lastTOut
                      : DashCheckPoint.P_lastTOut * -1}
                    %
                  </span>
                </li>
              </LightTooltip>
            </li>

            <li className="generalInf">
              <LightTooltip
                title="Tiempo fuera acumulado"
                followCursor
                TransitionComponent={Fade}
              >
                <li className="generalInf IconSvgCheck">
                  <Icon component={checkPoint1} className="icon" />
                  <span>{DashCheckPoint.P_TOutMS}</span>
                </li>
              </LightTooltip>

              <LightTooltip
                title="Relación mes anterior"
                followCursor
                TransitionComponent={Fade}
              >
                <li className="generalInf">
                  <FontAwesomeIcon
                    icon={
                      DashCheckPoint.P_lastTOutMS > 0 ? faCircleArrowUp : faCircleArrowDown
                    }
                    className={`icon ${
                      DashCheckPoint.P_lastTOutMS > 0 ? 'icon-negative' : 'icon-positive'
                    }`}
                  />
                  <span>
                    {DashCheckPoint.P_lastTOutMS > 0
                      ? DashCheckPoint.P_lastTOutMS
                      : DashCheckPoint.P_lastTOutMS * -1}
                    %
                  </span>
                </li>
              </LightTooltip>
            </li>
          </div>

          <ClientBanner
            items={DashCheckPoint.staffs}
            renderItem={(client, id) => (
              <>
                <LightTooltip title={`${client.name || ''}`} followCursor>
                  <img
                    className="client-photo"
                    src={
                      client.photo !== 'undefined' ? client.photo : '/images/icons/user.png'
                    }
                    alt={client.name || 'User'}
                  />
                </LightTooltip>

                <LightTooltip title="Acciones tomadas" followCursor>
                  <p className="client-info">{client.S_TT || 0} /</p>
                </LightTooltip>

                <LightTooltip title="Acciones resueltas" followCursor>
                  <p className="client-info">{client.S_TR || 0} /</p>
                </LightTooltip>

                <LightTooltip title="Eficiencia" followCursor>
                  <p className="client-info">{client.S_OK || 0}%</p>
                </LightTooltip>

                <LightTooltip title="Tiempos fuera / acumulados" followCursor>
                  <p className="client-info">
                    {`/ ${client.S_TOut || 0} / ${client.S_TOutMS || 0}`}
                  </p>
                </LightTooltip>
              </>
            )}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: slider ? `2.5rem` : '0rem',
              maxHeight: slider ? '400px' : '0px',
              overflow: 'hidden',
              transition: 'max-height 0.5s linear 0s, margin-top 0.5s linear 0s'
            }}
          >
            <div style={{ width: '45%' }}>
              <ReactECharts
                option={barChartOptions}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
            <div style={{ width: '55%' }}>
              <ReactECharts
                option={barHorizontal}
                style={{ height: '400px', width: '100%' }}
              />
            </div>
          </div>

          <div className="dashboard-search-section-CheckPoint">
            <h3 className="dashboard-barStatus">ESTATUS</h3>
          </div>

          <div className="tickets-container">
            <div className="Check-header">
              <div className="Check-info-header">
                <span className="Check-progress-svg"></span>
                <span className="Check-namePlan">PLAN</span>
                <span className="Check-status">ESTADO</span>
                <span className="Check-progress">AVANCE</span>
                <span className="Check-nameStaff">TRABAJADOR</span>
                <span className="Check-nameLastPlan">LOCACIÓN</span>
                <span className="Check-AddressLastPlan">DIRECCIÓN</span>
                <span className="Check-lastUpdateTime">HORA</span>
                <span className="Check-TimeOut">T.O</span>
                <span className="Check-lastPhoto">FOTO</span>
                <div className="Check-actions">ACCIONES</div>
              </div>
            </div>

            {DashCheckPoint.dashCheckReport.map((report, index) => (
              <div key={index} className="Check">
                <div className="Check-info">
                  <span className="Check-progress-svg">{statusSvg[report.status]}</span>
                  <span className="Check-namePlan">{report.namePlan}</span>
                  <span className="Check-status">{status[report.status]}</span>
                  <span className="Check-progress">
                    <>
                      <LightTooltip
                        title="Acciones Plan"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.T_actions || 0} / </p>
                      </LightTooltip>

                      <LightTooltip
                        title="Acciones realizadas"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.R_actions || 0} / </p>
                      </LightTooltip>

                      <LightTooltip title="Eficiencia" followCursor TransitionComponent={Fade}>
                        <p className="client-info">{report.P_action || 0}%</p>
                      </LightTooltip>
                    </>
                  </span>
                  <span className="Check-nameStaff">{report.nameStaff}</span>
                  <span className="Check-nameLastPlan">{report.nameLastPlan}</span>
                  <span className="Check-AddressLastPlan">{report.AddressLastPlan}</span>
                  <span className="Check-lastUpdateTime">{report.lastUpdateHour}</span>

                  <span className="Check-progress">
                    <>
                      <LightTooltip
                        title="Tiempos fuera"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.C_TOut || 0} / </p>
                      </LightTooltip>

                      <LightTooltip
                        title="T.O Acumulado"
                        followCursor
                        TransitionComponent={Fade}
                      >
                        <p className="client-info">{report.C_OutMS || 0}</p>
                      </LightTooltip>
                    </>
                  </span>

                  <PhotoTooltip
                    photos={[report.lastPhoto]}
                    altText={report.nameLastPlan}
                    altPhoto="/images/logo_inicio.png"
                    className="Check-photo"
                  />
                  {Actions && (
                    <div className="Check-actions">
                      {<Actions id={report.id} state={report.status} />}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dashboard
