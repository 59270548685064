import React, { useState } from 'react'
import { DatePicker, ConfigProvider } from 'antd'
import es_ES from 'antd/locale/es_ES' // Importa la localización en español
import dayjs from 'dayjs'
import 'dayjs/locale/es' // Importa el idioma español para dayjs
import type { Dayjs } from 'dayjs'
import 'antd/dist/reset.css' // Importa los estilos de antd
import './style.scss'

dayjs.locale('es') // Configura el idioma a español

const { RangePicker } = DatePicker

interface RangePickerProps {
  defaultValue?: [Dayjs, Dayjs]
  label1: string
  label2: string
  onchange: (value: [string, string]) => void
}

const DateRangePicker: React.FC<RangePickerProps> = ({
  defaultValue,
  label1,
  label2,
  onchange
}) => {
  // Estado para controlar los meses visibles
  const [months, setMonths] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, 'month'), // Mes anterior
    dayjs() // Mes actual
  ])

  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs]>([
    dayjs().subtract(1, 'month'), // Mes anterior
    dayjs() // Mes actual
  ])

  const handleDateChange = (dates: [string, string]) => {
    onchange(dates)
  }

  // Deshabilitar fechas futuras
  const disabledDate = (current: Dayjs) => {
    return current && current > dayjs().endOf('day') // No permite seleccionar fechas futuras
  }

  // Actualizar los meses visibles al abrir el calendario
  const handleOpenChange = (open: boolean) => {
    if (open) {
      setMonths([dayjs().subtract(1, 'month'), dayjs()]) // Mantener el mes anterior y el actual
    }
  }

  // Controlar el cambio de meses visibles
  const handlePickerValueChange = (
    dates: [Dayjs, Dayjs],
    info: { source: 'panel' | 'reset'; mode: [any, any] }
  ) => {
    if (info.source === 'panel') {
      setPickerValue(dates) // Actualiza los meses visibles solo si se selecciona manualmente
    }
  }

  return (
    <ConfigProvider locale={es_ES}>
      <div className='date-range-picker2-container'>
        <RangePicker
          placeholder={[label1, label2]}
          onChange={(_date, dateString) => {
            handleDateChange(dateString)
          }}
          defaultValue={defaultValue}
          format='DD/MM/YY' // Define el formato de la fecha
          className='date-picker2-wrapper'
          popupClassName='date-picker2-dropdown'
          placement='bottomRight' // Hace que el calendario se abra hacia abajo y a la derecha
          disabledDate={disabledDate} // Deshabilita las fechas futuras
          defaultPickerValue={months} // Establece los meses visibles inicialmente
          onOpenChange={handleOpenChange} // Mantiene los meses visibles al abrir el calendario
          pickerValue={pickerValue} // Controla los meses visibles
          onPickerValueChange={handlePickerValueChange} // Maneja el cambio de meses visibles
        />
      </div>
    </ConfigProvider>
  )
}

export default DateRangePicker
