import React, { FC, useEffect, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { ExpandLess, ExpandMore, Add } from '@mui/icons-material'

import Modal from '../../GeneralComponents/Modal'

import DeleteButton from '../../Table/components/DeleteButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import { PaymentConceptResident, statement } from '../../../types/Payments'
import { getstatement } from '../../../services/Payments'
import './style.scss'
import {
  deleteConceptResident,
  newCuota,
  statusConceptResident,
  updateCuota
} from '../../../services/PaymentsConceptsResident'
import ConceptsRes from './Concepts'
import Switch from '../../Form/Switch'

const StatementResident: FC<{ id: string; clientId: string }> = ({ id, clientId }) => {
  const { startLoading, stopLoading } = useLoading()
  const [slides, setSlides] = useState({ slider_1: true, slider_2: true, slider_3: true })
  const [data, setData] = useState<statement | undefined>(undefined)
  const [dataSelect, setDataSelect] = useState<PaymentConceptResident | undefined>()
  const [selectModal, setSelectModal] = useState<React.ReactNode>()
  const [isopenModal, setisopenModal] = useState<boolean>(false)

  const handleDelete = async (idItem:string) => {
    try {
      startLoading()
      await deleteConceptResident(idItem)
      getData()
      stopLoading()
    } catch (error) {
      console.error(error)
    }
  }

  const handleView = (value: PaymentConceptResident) => {
    setDataSelect(value)
  }

  const ChangerSliders = (nameSlider: 'slider_1' | 'slider_2' | 'slider_3') => {
    setSlides(prevSliders => ({
      ...prevSliders,
      [nameSlider]: !prevSliders[nameSlider] // Invertimos el valor
    }))
  }

  const Actions = ({ idItem, item }: { idItem: string; item: PaymentConceptResident }) => {
    return (
      <div className="actionsContainer">
        <Switch
          key={idItem}
          value={item.active}
          onChange={() => {
            statusConceptResident(idItem)
            getData()
          }}
        />
        <IconButton onClick={() => handleView(item)}>
          <VisibilityIcon style={{ color: 'white' }} />
        </IconButton>
        <DeleteButton OnDelete={() => handleDelete(idItem)} />
      </div>
    )
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (dataSelect) {
      openModal('cuotas', dataSelect) // Abre el modal cuando dataSelect cambie
    }
  }, [dataSelect])

  const getData = async () => {
    try {
      startLoading()
      const statement = await getstatement(id, clientId)
      setData(statement)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const openModal = (modal: any, lastData: PaymentConceptResident | undefined) => {
    if (modal === 'cuotas') {
      setSelectModal(
        <ConceptsRes
          lastData={lastData}
          residentId={id}
          clientId={clientId}
          onSubmit={dataSend => {
            onSubmitConceptsResident(dataSend)
          }}
        />
      )
      setisopenModal(true)
    }
  }

  const closeModal = () => {
    setDataSelect(undefined)
    setisopenModal(false)
  }

  const onSubmitConceptsResident = (dataSend: any) => {
    try {
      const data = {
        name: dataSend.name,
        active: true,
        clientId,
        residentId: id,
        paymentsConceptsId: dataSend.paymentsConceptsId
      }

      if (dataSelect && dataSelect.id) {
        updateCuota(data, dataSelect.id)
      } else {
        newCuota(data)
      }
    } catch (error) {
    } finally {
      closeModal()
      getData()
    }
  }

  return (
    <div className="StatementResident">
      {data && (
        <>
          <div className="title">
            <div className="svg">
              <IconButton onClick={() => ChangerSliders('slider_1')}>
                {slides.slider_1 ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
            <p>ESTADO DE CUENTA {data.date} </p>
          </div>
          <div
            className="containerSlider"
            style={{
              maxHeight: slides.slider_1 ? `${50 * (data.paymentDetaild.length + 1)}px` : '0px'
            }}
          >
            {data.paymentDetaild.map((detail, index) => (
              <div key={index} className="containerMap">
                <div className="details" style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}>
                  <span>{detail.type}</span>
                  <span>{detail.name}</span>
                  <span>{detail.date}</span>
                </div>
                <div className="PriceContainer">
                  <span>Q {detail.price}</span>
                </div>
              </div>
            ))}

            <div className="totalPrice">
              <div className="details" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                <span>SALDO AL CORTE </span>
              </div>
              <div className="PriceContainer">
                <span>Q {data.totalPrice}</span>
              </div>
            </div>
          </div>

          <div className="title">
            <div className="svg">
              <IconButton onClick={() => ChangerSliders('slider_2')}>
                {slides.slider_2 ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
            <p>CUOTAS</p>

            <div className="svgPlus">
              <IconButton onClick={() => openModal('cuotas', undefined)}>
                <Add style={{ color: 'white' }} />
              </IconButton>
            </div>
          </div>
          <div
            className="containerSlider"
            style={{
              maxHeight: slides.slider_2
                ? `${50 * data.paymentConceptResident.length}px`
                : '0px'
            }}
          >
            {data.paymentConceptResident.map((detail, index) => (
              <div key={index} className="containerMap">
                <div className="details" style={{ gridTemplateColumns: 'repeat(1, 1fr)' }}>
                  <span>{detail.name}</span>
                </div>
                <div className="PriceContainer">
                  <span>Q {detail.price}</span>
                </div>

                <Actions idItem={detail.id as string} item={detail} />
              </div>
            ))}
          </div>

          <div className="title">
            <div className="svg">
              <IconButton onClick={() => ChangerSliders('slider_3')}>
                {slides.slider_3 ? (
                  <ExpandMore style={{ color: 'white' }} />
                ) : (
                  <ExpandLess style={{ color: 'white' }} />
                )}
              </IconButton>
            </div>
            <p>RESERVAS</p>
          </div>
          <div
            className="containerSlider"
            style={{
              maxHeight: slides.slider_3 ? `${50 * data.resevations.length}px` : '0px'
            }}
          >
            {data.resevations.map((detail, index) => (
              <div key={index} className="containerMap">
                <div className="details" style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                  <span className="Resevations-name">{detail.name}</span>
                  <span className="Resevations-date">{detail.date}</span>
                </div>
                <div className="PriceContainer">
                  <span className="Resevations-type">Q {detail.price}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <Modal
        isOpen={isopenModal}
        onClose={closeModal}
        styleContainer={{ width: '350px', height: '380px' }}
      >
        {selectModal}
      </Modal>
    </div>
  )
}

export default StatementResident
