import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { HashRouter } from 'react-router-dom'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

const isInElectron = window && (window as any).ipcRenderer

if (isInElectron) {

  console.log('log desde buid ELECTRON 1')

  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <App isCheckIn={false} />
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
  )
} else {
  const rootElement = document.getElementById('root')
  console.log('log desde buid web 1')

  const renderApp = () => {
    render(
      <React.StrictMode>
        <Router>
          <App isCheckIn={false} />
        </Router>
      </React.StrictMode>,
      rootElement
    )
  }

  renderApp()
}
