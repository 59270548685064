import { ReactComponent as OkidDead } from '../../../assets/svg/OkidDead.svg'
import { ReactComponent as lowRF } from '../../../assets/svg/lowRF.svg'
import { ReactComponent as CheckDpi } from '../../../assets/svg/CheckDpi.svg'
import Icon from '@ant-design/icons'
import { DoneAll, Visibility, QrCode, PictureAsPdf, HighlightOff } from '@mui/icons-material'
import { Fade, IconButton } from '@mui/material'
import React, { FC, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useCheckIn } from '../../../Provider/CheckInProvider'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import { roleEnum } from '../../../types/user'
import Table, { TableColumn, enumTypeColumn } from '../../Table'
import ValidateEntry from '../../Table/components/ValidateEntry'
import { columnsCheckInOkId } from './columnsOkid'
import { useLoading } from '../../../Provider/LoadingProvider'
import { DownloadPdfOkId } from '../../../services/entries'
import { LightTooltip } from '../../tooltips/text'
import Modal from '../../GeneralComponents/Modal'

const TableCheckIn: FC<{ handleOpenModal: () => void }> = ({ handleOpenModal }) => {
  const { user, token } = useAuth()
  const { data, getDataCheckIn, setSearch, search, setLastEntry } = useCheckIn()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pdfUrl, setPdfUrl] = useState('')

  const { startLoading, stopLoading } = useLoading()

  const onChangeSearch = (value: string) => {
    setSearch(value)
  }

  const handlePageChange = (newPage: number) => {
    getDataCheckIn(search.length >= 3 ? search : '', newPage)
  }
  const handleIconClick = (item?: entries) => {
    console.info(item)
  }
  const handleView = (item?: entries) => {
    if (item) {
      setLastEntry(item)
    }
  }

  const handleModalClose = () => {
    setIsModalVisible(false)
    setPdfUrl('') // Limpia la URL del PDF
  }

  const handlePdfOkId = async (id?: string) => {
    if (id) {
      try {
        startLoading()
        console.log(id)
        const dataPdf64 = await DownloadPdfOkId(id, token) // Pedimos el base64 del pdf

        // Convierte el base64 en un objeto Blob
        const pdfBlob = base64ToBlob(dataPdf64?.pdf64, 'application/pdf')

        // Crea un enlace para ver el PDF
        const url = URL.createObjectURL(pdfBlob)
        setPdfUrl(url)

        // Abre el modal
        setIsModalVisible(true)
      } catch (error) {
        console.error('Error al crear reporte', error) // Maneja el error
      } finally {
        stopLoading()
      }
    }
  }

  // Función para convertir base64 a Blob
  const base64ToBlob = (base64: string, contentType: string) => {
    const byteCharacters = atob(base64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)
    return new Blob([byteArray], { type: contentType })
  }

  const Actions = ({ id, item }: { id: string; item?: entries }) => {
    return (
      <>
        {user?.canApprove && item?.approved === entriesApprovedEnum.WAITING && (
          <ValidateEntry id={id} />
        )}
        {item?.OKIDNumber && (
          <IconButton onClick={() => handlePdfOkId(item?.OKIDNumber)}>
            <PictureAsPdf style={{ color: 'red' }} />
          </IconButton>
        )}

        <IconButton onClick={() => handleIconClick(item)}>
          <QrCode
            style={{
              color: item?.approved ? (item?.timeOut ? 'red' : 'green') : 'yellow'
            }}
          />
        </IconButton>
        <IconButton onClick={() => handleView(item)}>
          <Visibility style={{ color: 'white' }} />
        </IconButton>
      </>
    )
  }

  const svgOkid = (item: entries | undefined) => {
    const icons = [] // Array para acumular los íconos

    if (item?.okid) {
      const dataOkid = parseInt(item?.okid)
      const RF = item.trm ? parseInt(item?.trm) : 0
      const PORCET_OK = parseInt(process.env.REACT_APP_API_MIN_PORCET_OK!)
      const PORCET_RF = parseInt(process.env.REACT_APP_API_MIN_PORCET_RF!)

      // Verificaciones y acumulación de íconos
      if (!item.DNI) {
        icons.push(
          <LightTooltip title={'▪️ DPI NO ENCONTRADO'} TransitionComponent={Fade}>
            <HighlightOff
              key="no-dni"
              style={{ color: 'red', fontSize: '2rem' }}
              className="alertOkid"
            />
          </LightTooltip>
        )
      }
      if (item.FECHA_DEFUNCION) {
        icons.push(
          <LightTooltip title={'▪️ IDENTIDAD FALLECIDA'} TransitionComponent={Fade}>
            <Icon
              key="defuncion"
              component={OkidDead}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }
      if (dataOkid <= PORCET_OK) {
        icons.push(
          <LightTooltip title={'▪️ ANALIZAR DPI'} TransitionComponent={Fade}>
            <Icon
              key="low-okid"
              component={CheckDpi}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }
      if (RF <= PORCET_RF) {
        icons.push(
          <LightTooltip title={'▪️ RECONOCIMIENTO FACIAL BAJO'} TransitionComponent={Fade}>
            <Icon
              key="low-rf"
              component={lowRF}
              style={{ fontSize: '2rem' }}
              className="alertOkidDead"
            />
          </LightTooltip>
        )
      }

      // Si no hay alertas, se agrega un ícono de estado "ok"
      if (icons.length === 0) {
        icons.push(
          <LightTooltip title={'✔️ CODIGO 10 10'} TransitionComponent={Fade}>
            <DoneAll key="ok" style={{ color: 'green', fontSize: '2rem' }} />
          </LightTooltip>
        )
      }
    }

    return <div className="flex-container">{icons}</div> // Retorna todos los íconos dentro de un contenedor div
  }

  const showAProved: TableColumn[] = [
    ...columnsCheckInOkId,
    {
      title: 'ESTADOS',
      dataIndex: 'approved',
      key: 'approved',
      type: enumTypeColumn.custom,
      CustomRender: ({ item }: { id: string; item?: entries }) => {
        return (
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '30px',
                gap: '0.5rem'
              }}
            >
              {item && svgOkid(item)}
            </div>
          </>
        )
      }
    }
  ]
  return (
    <>
      <Table
        onChangeSearch={onChangeSearch}
        pagination={data}
        handleOpenModal={handleOpenModal}
        onPageChange={handlePageChange}
        columns={showAProved}
        itemsPerPage={6}
        Actions={Actions}
        canAdd={user?.role === roleEnum.ADMIN ? false : true}
      />

      <Modal
        isOpen={isModalVisible}
        styleContainer={{
          width: ' 100%',
          height: ' 100%',
          padding: '0px',
          background: 'rgb(0 0 0 / 0%)',
          overflow: 'hidden'
        }}
        styleContainerContent={{ padding: '0 60px', height: '100%', maxHeight: '100vh' }}
        onClose={handleModalClose}
      >
        <object
          data={pdfUrl}
          type="application/pdf"
          width="100%"
          height="99%" // Ajusta la altura según tus necesidades
        >
          <p>
            El archivo PDF no se puede visualizar.{' '}
            <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
              Descargar PDF
            </a>
          </p>
        </object>
      </Modal>
    </>
  )
}

export default TableCheckIn
