import axiosInstance from './axiosInstance'

export const savePaymentDate = async (params: object, token?: string): Promise<boolean> => {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`
      }
    : {}
  try {
    const response = await axiosInstance.post(`/api/manageress/savePaymentDate`, params, {
      headers: {
        'Content-Type': 'multipart/form-data;',
        ...headers
      }
    })

    return response.data
  } catch (error) {
    // Manejo de errores
    console.error('Error guardando fechas de cobros', error)
    throw error
  }
}
