import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import io from 'socket.io-client'
import { listEntriesByClient } from '../services/entries'
import { brand } from '../types/brand'
import { entries } from '../types/entries'
import { Pagination } from '../types/types'
import { useAuth } from './AuthProvider'
import { useLoading } from './LoadingProvider'

import { listBrandsAll } from '../services/brand'
import { convertEntriesAttributes } from '../utils/utils'
type CheckInContextType = {
  data: Pagination<entries>
  search: string
  setSearch: Dispatch<SetStateAction<string>>
  brands: brand[]
  getDataCheckIn: (searchValue: string, page: number) => void
  lastEntry: entries | null
  setLastEntry: Dispatch<SetStateAction<entries | null>>
}

const CheckInContext = createContext<CheckInContextType | undefined>(undefined)

type CheckInProviderProps = {
  children: ReactNode
}

const CheckInProvider: React.FC<CheckInProviderProps> = ({ children }) => {
  const [search, setSearch] = useState('')
  const { startLoading, stopLoading, isElectron } = useLoading()
  const { token } = useAuth()
  const { user } = useAuth()
  const [filteredBySearch, setFilteredBySearch] = useState(false)
  const [brands, setBrands] = useState<brand[]>([])
  const [userInteracted, setUserInteracted] = useState(false)
  const sound_10_10 = useRef<HTMLAudioElement>(null)
  const sound_20_20 = useRef<HTMLAudioElement>(null)

  // const [destine, setDestine] = useState<string[]>([])
  const [lastEntry, setLastEntry] = useState<entries | null>(null)
  const [data, setData] = useState<Pagination<entries>>({
    currentPage: 1,
    hasNextPage: false,
    hasPrevPage: false,
    total: 1,
    totalPages: 1,
    data: []
  })
  const getDataCheckIn = async (searchValue: string, page: number, noLoading?: boolean) => {
    try {
      !noLoading && startLoading()
      const entries = await listEntriesByClient({
        id: user?.clientId as string,
        params: { page, search: searchValue },
        token
      })
      setData({
        ...entries,
        data: entries.data.map(convertEntriesAttributes)
      })
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }
  const getData = async () => {
    try {
      const brands = await listBrandsAll(token)
      setBrands(brands)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    if (search.length >= 3) {
      setFilteredBySearch(true)
      getDataCheckIn(search, 1)
    } else if (filteredBySearch) {
      setFilteredBySearch(false)
      getDataCheckIn('', 1)
    }
  }, [search])

  useEffect(() => {
    getDataCheckIn('', 1)
  }, [])

  const handleUserInteraction = () => {
    setUserInteracted(true)
    document.removeEventListener('click', handleUserInteraction)
    document.removeEventListener('keydown', handleUserInteraction)
    document.removeEventListener('mousemove', handleUserInteraction) // También mousemove
    document.removeEventListener('scroll', handleUserInteraction) // También scroll
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_API_URL_BACKEND as string)
    document.addEventListener('click', handleUserInteraction)
    document.addEventListener('keydown', handleUserInteraction)
    document.addEventListener('mousemove', handleUserInteraction)
    document.addEventListener('scroll', handleUserInteraction)

    socket.on(`newEntry-${user?.clientId}`, (entry: { newEntry: entries }) => {
      const newEntry = entry.newEntry
      if (search !== '' || data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
      let soundTrack: React.RefObject<HTMLAudioElement>

      const dataOkid = newEntry.okid ? parseInt(newEntry?.okid) : 0
      const RF = newEntry.trm ? parseInt(newEntry?.trm) : 0
      const PORCET_OK = parseInt(process.env.REACT_APP_API_MIN_PORCET_OK!)
      const PORCET_RF = parseInt(process.env.REACT_APP_API_MIN_PORCET_RF!)

      if (
        newEntry.DNI &&
        !newEntry.FECHA_DEFUNCION &&
        dataOkid >= PORCET_OK &&
        RF >= PORCET_RF
      ) {
        soundTrack = sound_10_10
      } else {
        soundTrack = sound_20_20
      }

      if (soundTrack.current && (userInteracted || isElectron)) {
        soundTrack.current.play().catch(error => {
          console.error('Error reproduciendo audio:', error)
        })
      }
    })
    socket.on(`updateEntry-${user?.clientId}`, () => {
      if (search !== '' || data.currentPage !== 1) return
      getDataCheckIn('', 1, true)
    })

    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    if (!brands.length /*  || !destine.length */) {
      getData()
    }
  }, [])
  useEffect(() => {
    setLastEntry(data.data[0])
  }, [data.data])

  return (
    <>
      <CheckInContext.Provider
        value={{
          setLastEntry,
          lastEntry,
          data,
          brands,
          search,
          setSearch,
          getDataCheckIn
        }}
      >
        <>{children}</>
      </CheckInContext.Provider>

      <audio ref={sound_20_20} src="sounds/codigo_20_20.mp3" />
      <audio ref={sound_10_10} src="sounds/codigo_10_10.mp3" />
    </>
  )
}

const useCheckIn = () => {
  const context = useContext(CheckInContext)
  if (!context) {
    throw new Error('useCheckIn must be used within CheckInProvider')
  }
  return context
}

export { CheckInProvider, useCheckIn }
