export class entries {
  id!: string
  name!: string
  lastName!: string
  destination!: string
  category!: string
  brand!: string
  photo?: string
  approved?: entriesApprovedEnum
  DNI_A?: string
  DNI_B?: string
  DNI?: string
  dateIn!: Date
  dateOut?: Date
  brandId?: string
  timeIn!: Date
  timeOut?: Date
  showId?: string
  origin?: string
  qrCode?: string
  okid?: string
  trm?: string
  OKIDNumber?: string
  cameraIn?: string
  cameraOut?: string
  FECHA_DEFUNCION?: string
  authorizedIn?: string
  authorizedOut?: string
  authorizedTypeiN?: string
  authorizedTypeOut?: string
  nameAutorizeIn?: string
  nameAutorizeOut?: string
  namesResident?: string //aqui solo sera para cuando sea por lectura de qr
}

export enum entriesApprovedEnum {
  'WAITING' = 'WAITING',
  'UNANSWERED' = 'UNANSWERED',
  'CALLING' = 'CALLING',
  'APPROVED' = 'APPROVED',
  'REJECT' = 'REJECT',
  'RECESSION' = 'RECESSION',
  'LEAVE' = 'LEAVE',
  'NO_LEAVE' = 'NO_LEAVE'
}

export class entriesCreate {
  id!: string
  name!: string
  lastName!: string
  destination!: string
  category!: string
  brand!: string
  dateIn!: Date
  dateOut!: Date
  timeIn!: string
  timeOut!: string
}
