import { TableColumn, enumTypeColumn } from '../../Table'

export const columnsCheckInOkId: TableColumn[] = [
  {
    title: 'ID',
    dataIndex: 'showId',
    key: 'showId',
    type: enumTypeColumn.showId
  },
  {
    title: 'DNI',
    dataIndex: 'DNI',
    key: 'DNI',
    type: enumTypeColumn.string
  },
  {
    title: 'FOTO',
    dataIndex: '3photo',
    dataArray: ['photo', 'DNI_A', 'DNI_B'],
    key: 'dataArray',
    type: enumTypeColumn.photo3
  },
  {
    title: 'NOMBRE',
    dataIndex: 'name',
    key: 'name',
    type: enumTypeColumn.string
  },
  {
    title: 'APELLIDO',
    dataIndex: 'lastName',
    key: 'lastName',
    type: enumTypeColumn.string
  },
  {
    title: 'DESTINO',
    dataIndex: 'destination',
    key: 'destination',
    type: enumTypeColumn.string
  },
  {
    title: 'MARCA',
    dataIndex: 'brand',
    key: 'brand',
    type: enumTypeColumn.string
  },

  {
    title: 'INGRESO',
    dataIndex: 'timeIn',
    key: 'timeIn',
    type: enumTypeColumn.date,
    options: 'full'
  },
  {
    title: 'EGRESO',
    dataIndex: 'timeOut',
    key: 'timeOut',
    type: enumTypeColumn.date,
    options: 'full'
  },
  {
    title: 'RF',
    titleHover: 'RECONOCIMIENTO FACIAL %',
    dataIndex: 'trm',
    key: 'trm',
    type: enumTypeColumn.porcent,
    options: 'day'
  },

  {
    title: 'OKID',
    titleHover: 'ANALISIS IDENTIDAD %',
    dataIndex: 'okid',
    key: 'okid',
    type: enumTypeColumn.porcent
  }
]
