import moment from 'moment-timezone'
import { entries } from '../types/entries'
import { typeQrEnumString } from '../types/qr'
export const dataURLtoBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(',')[1])
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export const transformDate = (
  fechaISO: string,
  type: 'full' | 'day' | 'time' = 'full'
): string => {
  if (!fechaISO) return ''

  const fecha = moment.tz(fechaISO, 'America/Guatemala')

  const dia = fecha.format('DD')
  const mes = fecha.format('MM')
  const anio = fecha.format('YYYY')
  const horas = fecha.format('HH')
  const horas12 = fecha.format('hh')
  const minutos = fecha.format('mm')
  const amPm = fecha.format('A') // AM o PM

  let cadenaFormateada = ''

  if (type === 'full') {
    cadenaFormateada = `${dia}-${mes}-${anio} ${horas12}:${minutos} ${amPm}`
  }

  if (type === 'day') {
    cadenaFormateada = `${dia}-${mes}-${anio}`
  }

  if (type === 'time') {
    cadenaFormateada = `${horas}:${minutos}`
  }

  return cadenaFormateada
}
type PlainObject = { [key: string]: any }

export function cleanObject(obj: PlainObject): PlainObject {
  // Asegúrate de que `obj` sea un objeto
  if (obj && typeof obj === 'object') {
    // Recorre todas las claves del objeto
    Object.keys(obj).forEach(key => {
      const value = obj[key]

      // Si el valor es un objeto, aplica recursivamente la función
      if (value && typeof value === 'object') {
        cleanObject(value)

        // Elimina la propiedad si está vacía después de limpiar
        if (Object.keys(value).length === 0) {
          delete obj[key]
        }
      } else if (value === null || value === '' || value === undefined) {
        // Elimina la propiedad si es nula, vacía o indefinida
        delete obj[key]
      }
    })
  }

  return obj
}

export const convertEntriesAttributes = (values: entries): entries => {
  const type = values?.qrCode?.slice(0, 4)
  return {
    ...values,
    timeIn: values.dateIn,
    timeOut: values.dateOut,
    origin: values.origin ?? typeQrEnumString[type as keyof typeof typeQrEnumString]
  }
}
