import React, { useState, useEffect } from 'react'
import './style.scss'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

interface SelectOption {
  value: string
  label: string
}

interface SelectProps {
  options: SelectOption[]
  value: string[]
  Name: string
  placeholder?: string
  onChange?: (
    event: SelectChangeEvent<string[]>,
    child: React.ReactNode
  ) => void
  label?: string
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: '#14113f',
      color: 'white'
    }
  }
}

const MultiSelect: React.FC<SelectProps> = ({
  options,
  placeholder,
  onChange,
  label,
  value = [],
  Name
}) => {
  const [selected, setSelected] = useState<string[]>(value)

  const handleChange: any = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event
    setSelected(typeof value === 'string' ? value.split(',') : value)
    if (onChange) {
      onChange(event, null) // Pasar `null` para el segundo argumento (child)
    }
  }

  useEffect(() => {
    setSelected(value)
  }, [value])

  return (
    <div className='multi-select-container2'>
      {label && <InputLabel className='multi-input-label2'>{label}</InputLabel>}
      <FormControl
        sx={{
          m: 1,
          width: '100%', // Se ajusta al ancho del contenedor
          height: '30px',
          borderColor: 'light-dark(rgb(118, 118, 118), rgb(133, 133, 133))',
          backgroundColor: '#14113f',
          borderRadius: '11px',
          color: 'white',
          fontSize: '14px',
          '.MuiOutlinedInput-root': {
            color: 'white',
            height: '30px', // Altura del contenedor
            '& .MuiOutlinedInput-notchedOutline': {
              borderRadius: '11px',
              borderColor: 'light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderRadius: '11px',
              borderColor: 'light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderRadius: '11px',
              borderColor: 'light-dark(rgb(118, 118, 118), rgb(133, 133, 133))'
            }
          },
          '.MuiInputLabel-root': {
            color: 'white',
            fontSize: '14px'
          },
          '.MuiSvgIcon-root': {
            color: 'white'
          }
        }}>
        <Select
          className='multi-select-element2'
          multiple
          displayEmpty
          value={selected}
          name={Name}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (Array.isArray(selected)) {
              return selected.length === 0 ? (
                <em>{placeholder}</em>
              ) : (
                selected
                  .map(
                    (value) =>
                      options.find((option) => option.value === value)?.label
                  )
                  .join(', ')
              )
            }
            return <em>{placeholder}</em>
          }}
          MenuProps={MenuProps}>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              className='multi-item2'>
              <Checkbox
                checked={selected.indexOf(option.value) > -1}
                className='multi-checkbox2'
              />
              <ListItemText
                primary={option.label}
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default MultiSelect
