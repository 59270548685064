import React, { useEffect, useState, useMemo } from 'react'
/* import { useLoading } from '../../Provider/LoadingProvider' */
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { useLoading } from '../../Provider/LoadingProvider'

import Button from '../../components/Form/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloud } from '@fortawesome/free-solid-svg-icons'
import { savePaymentDate } from '../../services/manageress'

const Component: React.FC = () => {
  interface PaymentDate {
    [key: number]: number | null
  }

  /*   const { startLoading, stopLoading } = useLoading()*/

  const { user, token, initial } = useAuth()
  const { startLoading, stopLoading } = useLoading()

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]
  const days = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']

  const [selectedDates, setSelectedDates] = useState<PaymentDate>({})
  const [hasChanges, setHasChanges] = useState<boolean>(false)

  const paymentDate: PaymentDate = useMemo(() => {
    if (!user?.client?.paymentDate) return {}

    if (typeof user.client.paymentDate === 'string') {
      try {
        return JSON.parse(user.client.paymentDate)
      } catch (error) {
        console.error('Invalid JSON string:', user.client.paymentDate)
        return {}
      }
    }

    return user.client.paymentDate
  }, [user])

  const getFirstWeekday = (month: number) => {
    let day = 1
    while (true) {
      const date = new Date(2024, month, day)
      const dayOfWeek = date.getDay()
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        return day
      }
      day++
    }
  }

  useEffect(() => {
    const initialSelectedDates: PaymentDate = { ...paymentDate }

    for (let i = 0; i < months.length; i++) {
      const monthIndex = i + 1
      if (!initialSelectedDates[monthIndex]) {
        initialSelectedDates[monthIndex] = getFirstWeekday(i)
      }
    }

    setSelectedDates(initialSelectedDates)
  }, [user, months.length, paymentDate])

  useEffect(() => {
    const hasChanges = Object.keys(selectedDates).some(
      (month) => selectedDates[parseInt(month)] !== paymentDate[parseInt(month)]
    )

    setHasChanges(hasChanges)
  }, [selectedDates, paymentDate])

  const handleDateClick = (month: number, day: number) => {
    setSelectedDates((prevSelectedDates) => ({
      ...prevSelectedDates,
      [month]: (prevSelectedDates[month] = day)
    }))
  }

  const handleSaveClick = async () => {
    if (hasChanges) {
      try {
        startLoading()
        const params = JSON.stringify(selectedDates)
        await savePaymentDate(
          { paymentDate: params, id: user?.clientId },
          token
        )
        await initial()
      } catch (error) {
        console.error(error)
      } finally {
        stopLoading()
      }
    }
  }

  return (
    <div>
      <div className='title'>
        <h4>Fechas de pagos</h4>
        <p></p>
      </div>

      <div className='containerButtons'>
        <Button
          text='Guardar'
          styleType={hasChanges ? `normal` : `disable`}
          icon={
            <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />
          }
          onClick={handleSaveClick}
        />
      </div>

      <div className='gridContainer'>
        {months.map((month, index) => (
          <div key={index} className='calendarMonth'>
            <div
              className={
                index === new Date().getMonth()
                  ? 'monthHeaderSelect'
                  : `monthHeader`
              }>
              {month}
            </div>
            <div className='DaysCalendar'>
              {days.map((dia) => (
                <div key={dia} className='DayCalendar'>
                  {dia}
                </div>
              ))}
            </div>
            <div className='daysGrid'>
              {Array.from({ length: 42 }, (_, i) => {
                const day = i - (new Date(2024, index, 1).getDay() - 1)
                const isCurrentMonth =
                  day > 0 && day <= new Date(2024, index + 1, 0).getDate()
                const isSelected = selectedDates[index + 1] === day

                return (
                  <button
                    key={i}
                    className={`
                      dayButton 
                      ${!isCurrentMonth ? 'inactive' : ''} 
                      ${isSelected ? 'selected' : ''}
                    `}
                    onClick={() => handleDateClick(index + 1, day)}
                    disabled={!isCurrentMonth}>
                    {isCurrentMonth ? day : ''}
                  </button>
                )
              })}
            </div>
          </div>
        ))}
      </div>
      <div className='secondContainerButtons'>
        <Button
          text='Guardar'
          styleType={hasChanges ? `normal` : `disable`}
          icon={
            <FontAwesomeIcon style={{ marginRight: '10px' }} icon={faCloud} />
          }
          onClick={handleSaveClick}
        />
      </div>
    </div>
  )
}

export default Component
