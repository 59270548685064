import React from 'react'
import { Switch as AntSwitch } from 'antd'
import './style.scss'
interface SwitchProps {
  value?: boolean
  onChange?: (checked: boolean) => void
  label?: string
}

const Switch: React.FC<SwitchProps> = ({ value, onChange, label }) => {
  return (
    <div className='switch-container'>
      {label && <label className='label'>{label}</label>}
      <AntSwitch checked={value} onChange={onChange} />
    </div>
  )
}

export default Switch
